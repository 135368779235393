<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-md-8 col-md-offset-2">
        <b-card border-variant="secondary">
          <b-card-body>
            <div class="row">
              <div class="col">
                <h4>{{ $t('ie.detailedTitle') }}</h4>
                <p>{{ $t('ie.detailedText') }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-center">
                <img src="../assets/img/browsers/edge_48x48.png" />
                <p>
                  <a
                    href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                    >Microsoft Edge</a
                  >
                </p>
              </div>
              <div class="col text-center">
                <img src="../assets/img/browsers/safari_48x48.png" />
                <p>
                  <a href="https://support.apple.com/downloads/safari"
                    >Apple Safari</a
                  >
                </p>
              </div>
              <div class="col text-center">
                <img src="../assets/img/browsers/chrome_48x48.png" />
                <p>
                  <a href="https://support.google.com/chrome/answer/95346"
                    >Google Chrome</a
                  >
                </p>
              </div>
              <div class="col text-center">
                <img src="../assets/img/browsers/firefox_48x48.png" />
                <p>
                  <a href="https://www.mozilla.org/en-US/firefox/new/"
                    >Mozilla Firefox</a
                  >
                </p>
              </div>
            </div>
          </b-card-body>
          <b-card-footer class="mt-3">
            <b-button @click="goBack()">
              <i class="bi bi-chevron-left fs-3"></i>
            </b-button>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IE',
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
a {
  color: black;
}

a:hover {
  text-decoration: underline;
}
</style>
